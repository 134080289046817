import React from 'react';
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {menuState, subMenuState} from "../../../recoil/atom";
import {useNavigate} from "react-router";

const Footer = () => {
    const navigate = useNavigate();
    const [selectedMenu] = useRecoilState(menuState);
    const [, setSelectedSubMenu] = useRecoilState(subMenuState);

    return (
        <MenuContainer>
            <MenuWrapper>
                <Menu selected={selectedMenu?.includes('about')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/about");
                      }}>
                    ABOUT
                </Menu>
                <Menu selected={selectedMenu?.includes('stockists')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/stockists");
                      }}>
                    STOCKIST
                </Menu>
                <Menu selected={selectedMenu?.includes('newsletter')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/newsletter");
                      }}>
                    NEWSLETTER
                </Menu>
                <Menu selected={selectedMenu?.includes('shipping-returns')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/shipping-returns");
                      }}>
                    SHIPPING&RETURNS
                </Menu>
                <Menu selected={selectedMenu?.includes('contact-legal')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/contact-legal");
                      }}>
                    CONTACT&LEGAL
                </Menu>
            </MenuWrapper>
        </MenuContainer>
    );
};

export default Footer;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100vw;
  min-width: calc(max(750px, 100vw));
  height: 143px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #000000;
`;
const MenuWrapper = styled.div`
  height: 100%;
  padding: 0 60px;
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 45px;
  text-align: center;
  align-items: center;
`;

const Menu = styled.div`
  width: fit-content;
  padding-right: 10px;
  padding-bottom: 30px;
  font-size: 15px;
  line-height: normal;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:#ffffff'};
`;
