import React, {useState} from 'react';
import styled from "styled-components";
import MainVideoSrc from "../../asset/offshoot.mp4"
import MainTeaserSrc from "../../asset/offshootdot-main.mp4"
import {useNavigate} from "react-router";
import BannerImgSrc from "../../asset/img__banner.png"
import Banner from "../component/Banner";
import CategoryPostItem from "../component/CategoryPostItem";
import {mainPosts} from "../../mockData";
import MainShopCollection from "../component/main/MainShopCollection";
import Footer from "./layout/Footer";
import Modal from "react-modal";
import {useRecoilState} from "recoil";
import {menuState, showModalState} from "../../recoil/atom";
import CloseIconSrc from "../../asset/icon__close.png";
import {newsLetterApi} from "../../api/newsLetter/NewsLetterApi";
import MagazineCoverImg from "../../asset/mock/main/magazine-cover.png";
import GoodImg from "../../asset/mock/main/lookbook-23fw-collection.png";
import HolidayImgSrc from "../../asset/mock/main/lookbook-holiday-collection.png";

const MainPage = () => {
    const [showModal, setShowModal] = useRecoilState(showModalState);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Check your email again.');
    const [value, setValue] = useState('');

    const [, setSelectedMenu] = useRecoilState(menuState);

    const navigate = useNavigate();
    const handlePostClick = (id) => {
        navigate(`/magazine/${id}`);
    }

    const images = [{id: 0, image: BannerImgSrc}, {id: 1, image: BannerImgSrc}];

    const handleClickSubscribeButton = async () => {
        if (value?.split("@").length === 2) {
            const id = value.split("@")[0];
            const domain = value.split("@")[1];
            try {
                await newsLetterApi.subscribe({id, domain});
                setModalGuideMsg('Subscription completed.');
                setModalOpen(true);
                setShowModal(false);
                setTimeout(() => setModalOpen(false), 1000);
                setValue("");
            } catch (e) {
                console.log(e)
            }
        } else {
            setModalGuideMsg('Please check the input value');
            setModalOpen(true);
            setTimeout(() => setModalOpen(false), 1000);
        }
    };

    return (
        <Container>
            <Background>
                <video className={'background-video'} autoPlay muted playsInline loop preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>
            <Filter/>
            <ContentsContainer>
                <Spacing top="30px"/>
                <ContentsWrapper>
                    <VideoWrapper>
                        <video style={{maxHeight: "100%", maxWidth: "100%", objectFit: "cover"}} autoPlay
                               muted playsInline loop preload="auto">
                            <source src={MainTeaserSrc} type="video/mp4"/>
                        </video>
                    </VideoWrapper>
                    <ImageWrapper onClick={() => {
                        window.open("https://shop.offshootdot.com");
                    }}>
                        <img src={MagazineCoverImg} width="185px" height="258px"/>
                        <div style={{
                            color: '#000',
                            fontSize: '30px',
                            fontWeight: '500'
                        }}>VISIT SHOP</div>
                    </ImageWrapper>
                </ContentsWrapper>
                <BannerWrapper>
                    <Banner images={images} speed={180000}/>
                </BannerWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[2].title}
                        titleStyle={{color: "#fff", size: "48px", weight: "500"}}
                        subTitle={mainPosts[2].subTitle}
                        subTitleStyle={{color: "#fff", size: "32px", weight: "500"}}
                        width="100%"
                        thumbnailWidth="40%"
                        height="520px"
                        thumbnailSrc={mainPosts[2].categoryThumbnail} color={mainPosts[2].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[2].id)}
                    />
                </ContentsWrapper>
                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[0].title}
                        titleStyle={{color: "#fff", size: "36px", weight: "500"}}
                        subTitle={mainPosts[0].subTitle}
                        subTitleStyle={{color: "#ffc2c2", size: "32px", weight: "500"}}
                        width="100%"
                        thumbnailWidth="40%"
                        height="520px"
                        thumbnailSrc={mainPosts[0].categoryThumbnail} color={mainPosts[0].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[0].id)}
                    />
                </ContentsWrapper>
                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[1].title}
                        titleStyle={{color: "#FF4D00", size: "48px", weight: "500"}}
                        subTitle={mainPosts[1].subTitle}
                        subTitleStyle={{color: "#FF4D00", size: "32px", weight: "500"}}
                        width="100%"
                        thumbnailWidth="40%"
                        height="520px"
                        thumbnailSrc={mainPosts[1].categoryThumbnail} color={mainPosts[1].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[1].id)}
                    />
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={"Monster Socks"}
                        titleStyle={{color: "#EBFF00", size: "48px", weight: "500"}}
                        subTitle={"Discover more details"}
                        subTitleStyle={{color: "#EBFF00", size: "32px", weight: "300"}}
                        width="100%"
                        thumbnailWidth="40%"
                        height="520px"
                        thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/monster_socks.png"} color={"#000000"}
                        showSubTitle
                        onClick={() => window.open("https://shop.offshootdot.com/collections/socks")}
                    />
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <MainShopCollection width="100%" height="fit-content" img={GoodImg}
                                        onClick={() => navigate(`shop/3d/collection/23-fw-taboo`)}
                                        name="OFFSHOOT 2023 TABOO COLLECTION"/>
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <HolidayImg src={HolidayImgSrc} onClick={() => navigate(`shop/3d/collection/holiday`)}/>
                </ContentsWrapper>
                <Spacing top="30px"/>
                <Footer/>
            </ContentsContainer>
            <Modal style={{
                content: {
                    width: "500px",
                    height: "584px",
                    border: "none",
                    borderRadius: "0",
                    backgroundColor: "#C02126",
                    color: "#000000",
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    display: 'contents',
                    top: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                },
            }}
                   isOpen={showModal}>
                <ModalContent>
                    <FlexBoxRow>
                        <FlexBoxRow style={{fontSize: '36px', fontWeight: '700'}}>Newsletter</FlexBoxRow>
                        <CloseButton src={CloseIconSrc} width="20px" onClick={() => setShowModal(false)}/>
                    </FlexBoxRow>
                    <FlexBoxRow style={{fontSize: '17px', fontWeight: '500'}}>
                        Sign up for monthly dispatches on fashion and fine art from the OFFSHOOT.
                    </FlexBoxRow>
                    <ModalImageWrapper onClick={() => navigate(`/shop/2d`)}>
                        <img src={MagazineCoverImg} width="231px" height="308px"/>
                    </ModalImageWrapper>
                    <FlexBoxColumn>
                        <EmailInput value={value} onChange={(e) => setValue(e.target.value)} placeholder="Your Email"/>
                        <Spacing top="10px"/>
                        <SubscribeButton onClick={handleClickSubscribeButton}>Subscribe</SubscribeButton>
                    </FlexBoxColumn>
                </ModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    // display: 'none'
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row'}}>
                    {modalGuideMsg}
                    <CloseButton src={CloseIconSrc} width="20px" height="20px" onClick={() => setModalOpen(false)}/>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MainPage;

const HolidayImg = styled.img`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;

  &:hover {
    cursor: pointer;
  }
`;
const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;
const ContentsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
`;

export const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;

const Background = styled.div`
  width: 100vw;
  display: flex;
  position: fixed;
  top: 80px;
  left: 0;
  .background-video {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
    z-index: -2;
  }
  //background-blend-mode: darken;
`;
const Filter = styled.div`
  position: fixed;
  top: 80px;
  //width: calc(max(100vw, 1630px));
  min-width: 100vw;
  min-height: 100vh;
  z-index: -2;
  background-color: white;
  mix-blend-mode: difference;
`;

const ContentsWrapper = styled.div`
  display: flex;
  //width: 1450px;
  width: 80vw;
  min-width: calc(max(600px, 80vw));
  justify-content: center;
  align-items: center;
  height: fit-content;
`;

const VideoWrapper = styled.div`
  //width: calc(1450px / 2);
  width: 40vw;
  min-width: calc(max(300px, 40vw));
  height: 337px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 40vw;
  min-width: calc(max(300px, 40vw));
  height: 337px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  box-sizing: border-box;
  background: #C02126;

  &:hover {
    cursor: pointer;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  //width: 1450px;
  width: 80vw;
  min-width: calc(max(600px, 80vw));
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px
`;

const ModalImageWrapper = styled.div`
  //width: calc(1450px / 2);
  width: 100%;
  height: 292px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const FlexBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FlexBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  width: 443px;
  height: 40px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  text-align: center;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  &:focus {
    border: 2px solid red;

  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const SubscribeButton = styled.div`
  background: #000;
  width: 443px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`;
