import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import MenuIconSrc from "../../asset/icon__menu.svg";
import {useNavigate} from "react-router";
import {useRecoilState} from "recoil";
import {menuState, subMenuState} from "../../recoil/atom";
import useRouterScrollTop from "../../hooks/useRouterScrollTop";

export const MenuContent = ({open, onClose}) => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    const scrollRef = useRef(null);

    useRouterScrollTop(scrollRef.current);

    const navigate = useNavigate();
    const [, setSelectedMenu] = useRecoilState(menuState);
    const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(subMenuState);

    return <>
        {open &&
        <MenuContainer ref={scrollRef}>
            <MenuElement
                onClick={() => {
                    setSelectedMenu("/magazine");
                    setSelectedSubMenu('all');
                    navigate("/magazine");
                    onClose();
                }}>MAGAZINE</MenuElement>
            <MenuElement
                onClick={() => {
                    setSelectedMenu("/shop");
                    setSelectedSubMenu('all');
                    navigate("/shop");
                    onClose();
                }}>SHOP</MenuElement>
            <MenuElement
                onClick={() => {
                    setSelectedMenu("/pistol");
                    setSelectedSubMenu('all');
                    navigate("/pistol");
                    onClose();
                }}>PISTOL</MenuElement>
            <MenuElement
                onClick={() => {
                    setSelectedMenu("/information/about");
                    setSelectedSubMenu('all');
                    navigate("/information/about");
                    onClose();
                }}>INFORMATION</MenuElement>
        </MenuContainer>
        }
    </>
}

const MenuContainer = styled.div`
  width: 200px;
  height: 160px;
  position: absolute;
  top: 61px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 3;
  background-color: #000;
  color: #fff;
  box-sizing: border-box;
`;
const MenuElement = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 40px;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  border-top: 1px solid #fff;
`;

const Menu = ({onClick}) => {
    return (
        <MenuIconWrapper>
            <MenuIcon onClick={onClick} src={MenuIconSrc}/>
        </MenuIconWrapper>
    );
};

export default Menu;

const MenuIconWrapper = styled.div`
  width: fit-content;
`;

const MenuIcon = styled.img`
  src: ${({src}) => src && src};
  width: 32px;
`;
