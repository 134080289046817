import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router";
import ImgSrc from "../../asset/img__main-shop.png"
import BannerImgSrc from "../../asset/img__banner.png"
import Banner from "../component/Banner";
import {mainPosts} from "../../mockData";
import MainCategoryPostItem from "../component/MainCategoryPostItem";
import MainVideoSrc from "../../asset/offshoot.mp4";
import MainTeaserSrc from "../../asset/offshootdot-main.mp4"
import MainGoodItem from "../component/MainGoodItem";
import Modal from "react-modal";
import CloseIconSrc from "../../asset/icon__close.png";
import {useRecoilState} from "recoil";
import {collectionState, menuState, showModalState, subMenuState} from "../../recoil/atom";
import {newsLetterApi} from "../../api/newsLetter/NewsLetterApi";
import GoodImg from "../../asset/mock/main/lookbook-23fw-collection-mo.png";
import MagazineCoverImg from "../../asset/mock/main/magazine-cover.png";
import HolidayImgSrc from "../../asset/mock/main/holiday-main-mo.png";

const MainPage = () => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    const [, setSelectedMenu] = useRecoilState(menuState);
    const [, setSelectedSubMenu] = useRecoilState(subMenuState);
    const [, setSelectedCollection] = useRecoilState(collectionState);
    const [showModal, setShowModal] = useRecoilState(showModalState);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Check your email again.');
    const [value, setValue] = useState('');

    const navigate = useNavigate();
    const handlePostClick = (id) => {
        navigate(`/magazine/${id}`);
    }

    const handleClickSubscribeButton = async () => {
        if (value?.split("@").length === 2) {
            const id = value.split("@")[0];
            const domain = value.split("@")[1];
            try {
                await newsLetterApi.subscribe({id, domain});
                setModalGuideMsg('Subscription completed.');
                setModalOpen(true);
                setShowModal(false);
                setTimeout(() => setModalOpen(false), 1000);
                setValue("");
            } catch (e) {
                console.log(e)
            }
        } else {
            setModalGuideMsg('Please check the input value');
            setModalOpen(true);
            setTimeout(() => setModalOpen(false), 1000);
        }
    };

    const images = [{id: 0, image: BannerImgSrc}, {id: 1, image: BannerImgSrc}];
    return (
        <Container>
            <Background>
                <video style={{objectFit: 'cover'}} height="100%" autoPlay muted playsInline loop
                       preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>
            <Filter/>
            <ContentsWrapperColumn>
                <VideoWrapper>
                    <video width="100%" style={{maxHeight: "100%", maxWidth: "100%", objectFit: "cover"}} autoPlay muted
                           playsInline controls loop preload="auto">
                        <source src={MainTeaserSrc} type="video/mp4"/>
                    </video>
                </VideoWrapper>
                <Banner images={images} speed={180000}/>
                <Spacing top="35px"/>
                <ImageWrapper onClick={() => {
                    window.open("https://shop.offshootdot.com");
                }}>
                    <img src={ImgSrc} height="290px"/>
                    <div style={{color: '#000', fontSize: '25px', fontWeight: '500', paddingTop: '10px'}}>VISIT SHOP
                    </div>
                </ImageWrapper>
            </ContentsWrapperColumn>
            <Spacing top="35px"/>
            <ContentsWrapperRow>
                <MainGoodItem img={GoodImg}
                              onClick={() => {
                                  setSelectedMenu("/shop/3d");
                                  setSelectedSubMenu("collection");
                                  setSelectedCollection('23-fw-taboo');
                                  navigate(`/shop/3d/collection/23-fw-taboo`);
                              }}
                              thumbnailWidth="0%" height="537px" name="OFFSHOOT 2023 TABOO COLLECTION"/>
            </ContentsWrapperRow>
            <Spacing top="35px"/>
            <ContentsWrapperColumn>
                {/*<MainCategoryPostItem*/}
                {/*    title={mainPosts[2].title}*/}
                {/*    titleStyle={{color: "#FAFF00", size: "20px", weight: "700"}}*/}
                {/*    subTitle={mainPosts[2].subTitle}*/}
                {/*    subTitleStyle={{color: "#FAFF00", size: "15px", weight: "500"}}*/}
                {/*    width="100%" thumbnailWidth="0%"*/}
                {/*    height="60vh"*/}
                {/*    thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/post3_mo.jpeg"}*/}
                {/*    showSubTitle*/}
                {/*    onClick={() => handlePostClick(mainPosts[2].id)}*/}
                {/*/>*/}
                <MainCategoryPostItem
                    title={mainPosts[0].title}
                    titleStyle={{color: "#FAFF00", size: "20px", weight: "700"}}
                    subTitle={mainPosts[0].subTitle}
                    subTitleStyle={{color: "#FAFF00", size: "15px", weight: "500"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/post1_mo.jpeg"}
                    showSubTitle
                    onClick={() => handlePostClick(mainPosts[0].id)}
                />
                <MainCategoryPostItem
                    title={mainPosts[1].title}
                    titleStyle={{color: "#43FF01", size: "20px", weight: "700"}}
                    subTitle={mainPosts[1].subTitle}
                    subTitleStyle={{color: "#43FF01", size: "15px", weight: "500"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/post2_mo.PNG"}
                    showSubTitle
                    onClick={() => handlePostClick(mainPosts[1].id)}
                />
            </ContentsWrapperColumn>
            <Spacing top="35px"/>
            <ContentsWrapperColumn>
                <MainCategoryPostItem
                    width="100%" thumbnailWidth="0%"
                    height="440px"
                    thumbnailSrc={HolidayImgSrc}
                    onClick={() => {
                        setSelectedMenu("/shop/3d");
                        setSelectedCollection("holiday");
                        setSelectedSubMenu("collection");
                        navigate(`/shop/3d/collection/holiday`);
                    }}
                />
            </ContentsWrapperColumn>
            <ContentsWrapperColumn>
                <MainCategoryPostItem
                    title={"Monster Socks"}
                    titleStyle={{color: "#EBFF00", size: "24px", weight: "700"}}
                    subTitle={"BUY NOW"}
                    subTitleStyle={{backgroundColor: "#000", display: "flex", height: "36px", alignItems: "center", justifyContent: "center",
                        color: "#EBFF00", size: "18x", weight: "500"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/monster_socks_mo.JPG"}
                    showSubTitle
                    onClick={() => window.open("https://shop.offshootdot.com/collections/socks")}
                />
                <Spacing top="75px"/>
            </ContentsWrapperColumn>
            <Modal style={{
                content: {
                    width: "80vw",
                    height: "100vw",
                    border: "none",
                    borderRadius: "0",
                    backgroundColor: "#C02126",
                    color: "#000000",
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '10px 15px',
                    boxSizing: 'border-box',
                },
                overlay: {
                    display: 'contents',
                    top: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                },
            }}
                   isOpen={showModal}>
                <ModalContent>
                    <FlexBoxRow>
                        <FlexBoxRow style={{fontSize: '24px', fontWeight: '700'}}>Newsletter</FlexBoxRow>
                        <CloseButton src={CloseIconSrc} width="20px" height="20px" onClick={() => setShowModal(false)}/>
                    </FlexBoxRow>
                    <FlexBoxRow style={{fontSize: '14px', fontWeight: '500'}}>
                        Sign up for monthly dispatches on fashion and fine art from the OFFSHOOT.
                    </FlexBoxRow>
                    <ModalImageWrapper onClick={() => {
                        setSelectedMenu("/shop/2d");
                        setSelectedSubMenu('all');
                        navigate(`/shop/2d`);
                    }}>
                        <img src={MagazineCoverImg} height="100%"/>
                    </ModalImageWrapper>
                    <FlexBoxColumn>
                        <EmailInput value={value} onChange={(e) => setValue(e.target.value)} placeholder="Your Email"/>
                        <Spacing top="3px"/>
                        <SubscribeButton onClick={handleClickSubscribeButton}>Subscribe</SubscribeButton>
                    </FlexBoxColumn>

                </ModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "80vw",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    // display: 'none'
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row'}}>
                    {modalGuideMsg}
                    <CloseButton src={CloseIconSrc} width="20px" height="20px" onClick={() => setModalOpen(false)}/>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MainPage;

const Background = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  width: 100vw;
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: -2;
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
`;
const Filter = styled.div`
  position: fixed;
  top: 50px;
  width: 100vw;
  //min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: -2;
  background-color: white;
  mix-blend-mode: difference;
`;

export const Container = styled.div`
  font-family: "Helvetica LT W05 Roman";
  display: flex;
  position: relative;
  width: 100vw;
  //height: calc(100vh - 150px);
  height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  flex-direction: column;
  align-items: center;
`;

export const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;

const ContentsWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

const ContentsWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  background: #000000;
`;

const ImageWrapper = styled.div`
  width: 300px;
  height: 396px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #C02126;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ModalImageWrapper = styled.div`
  //width: calc(1450px / 2);
  width: 100%;
  height: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const FlexBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FlexBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 35px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-family: inherit;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  text-align: center;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  &:focus {
    border: 2px solid red;

  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const SubscribeButton = styled.div`
  background: #000;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`;
